import {useContext} from "react";
import {PreviewContext} from "../components/providers/PreviewProvider/PreviewProvider";


export const usePreview = (previewId: string): boolean => {
    const activePreviews = useContext(PreviewContext);
    if (activePreviews.includes("all")) {
        return true;
    }
    return activePreviews.includes(previewId);
};