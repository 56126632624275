import apiResources from "../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import React from "react";
import {ChipArrayField, ConnectedForm, FormSection, TextInput} from "@cuda-react/core";

interface WizardLocalUsersProps {
    userData: any[],
    userDataLoading?: boolean,
    onSuccess: () => void
    onSubmit: () => void
    onError: (error: any) => void
};

export const WizardLocalUsers = (props: WizardLocalUsersProps) => {
    const {userData, onSuccess, onSubmit, onError} = props;

    return (
        <React.Fragment>
            <ConnectedForm
                disabled={userData.length > 4 || props.userDataLoading}
                resource={apiResources.identityUsers}
                formButtonToolbarProps={{
                    label: "tesseract.setupWizard.wizardStep2.userDirectories.addUser",
                    color: "secondary",
                    buttonsOnly: true
                }}
                initialValues={{name: "", email: ""}}
                formatRequestData={(data: any) => {
                    onSubmit();
                    return data;
                }}
                formatError={(error: any) => {
                    onError(error);
                    return error;
                }}
                onSubmitSuccess={onSuccess}
                clearOnSuccess
                create
                flat
            >
                <FormSection
                    title="tesseract.setupWizard.wizardStep2.userDirectories.localUserDirectory"
                    hideBorder
                >
                    <TextInput
                        source="name"
                        label="tesseract.setupWizard.wizardStep2.userDirectories.localUserName"
                        isRequired
                        disabled={userData.length > 4}
                    />
                    <TextInput
                        source="email"
                        label="tesseract.setupWizard.wizardStep2.userDirectories.localUserEmail"
                        isRequired
                        disabled={userData.length > 4}
                    />
                </FormSection>
            </ConnectedForm>
            <ChipArrayField data={{userData}} source="userData"/>
            <FormSection
                title="tesseract.setupWizard.wizardStep2.identityProviders.identityProvider"
                hideBorder
            >
                <Typography>
                    <Trans
                        i18nKey="tesseract.setupWizard.wizardStep2.identityProviders.localUserDirectoryDescription">
                        <strong/>
                    </Trans>
                </Typography>
            </FormSection>
        </React.Fragment>
    );
};