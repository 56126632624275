import React, {useCallback} from "react";
import apiResources from "../../../apiResources";
import {
    CrudTypes,
    Form,
    getArrayDataContent,
    getDataContent,
    SelectInput,
    useCrudFetch,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import WizardPage, {WizardPageProps} from "../WizardPage";
import {Skeleton} from "@barracuda-internal/bds-core";

export const getRandomSuffix = () => {
    const chars = "123456789ABCDEF";
    return new Array(6).fill(null).map(() => chars[Math.floor(Math.random() * chars.length)]).join("");
};

interface WizardStep1Props extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
    onSuccess?: () => void
}

const WizardStep1 = (props: WizardStep1Props) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysRegions, {virtualWanId})[0]);
    const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
    const bandwidthData = getDataContent(useCrudProps(apiResources.managedBandwidthsUsage)[0]?.data);
    const availableBandwidth = bandwidthData?.available > 0 ? bandwidthData.available : 0;
    regionChoices.sort((region1: any, region2: any) => (region1.key.localeCompare(region2.key)));
    const [translate] = useTranslation();

    const [, saving, crudSubmit] = useCrudFetch(CrudTypes.CREATE, apiResources.managedGateways, {});
    const [, savingPoe, crudSubmitPoe] = useCrudFetch(CrudTypes.CREATE, apiResources.pointsOfEntryCreate, {});
    const [gatewaysUuidData, loadingGatewayUuid, refetchGatewaysUuid] = useCrudProps(apiResources.gateways, {});
    const [, loadingTargetUuid, fetchGatewayUuidData] = useCrudFetch(CrudTypes.GET, apiResources.gateways, {});
    const gateways = gatewaysUuidData?.data?.content;

    const registerSubmitAction = (values: any) => {
        props.registerStepAction("Submit", {
            bandwidth: values.model ? values.model + " Mbps" : undefined,
            region: values.region,
            premade: !!values.gateways
        });
    };
    const registerFailAction = (error: any) => {
        props.registerStepAction("Fail", {error: JSON.stringify(error)});
    };

    const saveOnlyPoe = useCallback((values) => {
        registerSubmitAction(values);
        return crudSubmitPoe(
            {
                data: {
                    virtualWanId,
                    cgfs: [],
                    gateways: [values?.gateways],
                    sites: []
                }
            },
            {formPromise: true}
        ).then((response: { data?: any, error?: any }) => {
            if (!response?.error) {
                props.onSuccess?.();
            }
            return response?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw error;
        });
    }, [crudSubmitPoe, virtualWanId]);
    const save = useCallback((values) => {
        registerSubmitAction(values);
        return crudSubmit(
            {
                data: {
                    ...values,
                    virtualWanId,
                    name: `ZTNA-${values?.region}-${getRandomSuffix()}`
                }
            },
            {formPromise: true}
        ).then((response: { data?: any, error?: any }) => {
            if (!response?.error) {
                return fetchGatewayUuidData({id: response?.data?.id}).then(
                    (idData: any) => crudSubmitPoe(
                        {
                            data: {
                                virtualWanId,
                                cgfs: [],
                                gateways: [idData?.data?.uuid],
                                sites: []
                            }
                        },
                        {formPromise: true}
                    ).then((response: { data?: any, error?: any }) => {
                        if (!response?.error) {
                            props.onSuccess?.();
                        }
                        return response?.data;
                    }).finally(() => {
                        refetchGatewaysUuid();
                    })
                );
            }
            return response?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw error;
        });
    }, [crudSubmit, crudSubmitPoe, fetchGatewayUuidData, virtualWanId, props.registerStepAction, props.onSuccess]);

    return (
        <Form
            save={gateways?.length === 0 ? save : saveOnlyPoe}
            disabled={loadingGatewayUuid || saving || savingPoe || loadingTargetUuid}
            noToolbar
            flat
        >
            <WizardPage
                textHeader="tesseract.setupWizard.wizardStep1.textHeader"
                textSubHeader="tesseract.setupWizard.wizardStep1.textSubHeader"
                mainText="tesseract.setupWizard.wizardStep1.mainText"
                activeStep={0}
                {...props}
            >
                {loadingGatewayUuid ? (
                    <Skeleton sx={{padding: "0 32px", width: 568, height: 80}}/>
                ) : gateways?.length === 0 ? (
                    <React.Fragment>
                        <SelectInput
                            source="region"
                            label="tesseract.gateways.dialog.create.managed.region"
                            choices={regionChoices}
                            newStyle
                        />
                        <SelectInput
                            source="model"
                            label="tesseract.gateways.dialog.create.managed.bandwidth"
                            choices={bandwidthChoices}
                            additionalInfoLabel={availableBandwidth <= 99999 ? {
                                i18nKey: "tesseract.gateways.dialog.create.managed.bandwidthAvailable",
                                values: {availableBandwidth}
                            } : undefined}
                            validate={(value: number) => {
                                if (value && value > availableBandwidth) {
                                    return translate("tesseract.gateways.dialog.create.managed.invalidBandwidth", {availableBandwidth});
                                }
                            }}
                            newStyle
                        />
                    </React.Fragment>
                ) : (
                    <SelectInput
                        source="gateways"
                        label="tesseract.endpoint.pointsOfEntry.create.gateways"
                        choices={gateways}
                        optionValue="uuid"
                        newStyle
                    />
                )}
            </WizardPage>
        </Form>
    );
};


export default WizardStep1;