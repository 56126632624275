import {Card as BdsCard, CardContent, CardHeader, Grid, Typography} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import React, {ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {CardSizeOptions, CardSizes, DashboardCardProps} from "../../../typesAndConstants";
import {StyledComponentProps} from "@cuda-react/theme";
import {WidgetErrorIcon} from "@cuda-react/icons";
import FilterIcon from "@mui/icons-material/FilterAlt";

const previewScales: Record<CardSizes, string> = {
    [CardSizes.small]: "scale(0.9) translateY(-8%)",
    [CardSizes.medium]: "scale(0.75) translateY(-20%)",
    [CardSizes.large]: "scale(0.5) translateY(-50%)",
};

interface BaseBaseCardProps extends Pick<DashboardCardProps, "size" | "preview"> {
    /** the content of the card */
    children: ReactNode | ReactNode[],
    /** classname to be added to the root element */
    className?: string,
    /** html id for the root Card element */
    id?: string,
    /** the title to display for the card */
    title?: string | ReactNode,
    /** if true, displays no data message and blurs card */
    noData?: boolean,
    /** if true, displays an filter icon on top right */
    activeFilter?: boolean
}

const styles = (theme: Theme) => createStyles<string, BaseBaseCardProps>({
    root: {
        margin: 0,
        textAlign: "left",
        display: "inline-block",
        verticalAlign: "top",
        maxWidth: "100%",
        transform: ({preview, size}) => preview ? previewScales[size] : undefined,
        width: ({size}) => CardSizeOptions[size]?.width,
        height: ({size}) => CardSizeOptions[size]?.height,
        border: "unset",
        boxShadow: "none",
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderStyle: "solid"
    },
    cardHeader: {
        textAlign: "left",
        alignItems: "flex-start",
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 2, 0.25, 2),
        overflowY: "visible"
    },
    titleTypography: {
        fontSize: 16,
        fontWeight: "bold",
        overflowY: "visible",
        color: theme.palette.reporting.titleColor,
        "& *": {
            fontWeight: "bold"
        },
        minHeight: 28
    },
    cardContent: {
        minWidth: `100%`,
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        },
        overflowX: "hidden",
        overflowY: "auto",
        gridRowStart: 2,
        gridColumnEnd: 5,
        gridColumnStart: 1,
        height: ({title}) => title ? "calc(100% - 50px)" : "100%"
    },
    noDataContainer: {
        height: ({size}) => `calc(${CardSizeOptions[size]?.height}px - ${theme.spacing(10)})`,
        width: "100%"
    },
    noDataIcon: {
        width: 64,
        height: 64,
    },
    noDataMessage: {
        textAlign: "center",
        color: theme.palette.reporting.fontColor
    }
});
const useStyles = makeStyles(styles);

export interface BaseCardProps extends BaseBaseCardProps, StyledComponentProps<typeof styles> {
}

/**
 * A basic card (with optional title), that renders the given children as its content.
 */
export const BaseCard = (props: BaseCardProps) => {
    const {children, id = "cuda-generic-card", className, title, noData, activeFilter} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <BdsCard className={classNames(classes.root, className)} id={id}>
            {title && (
                <CardHeader
                    action={activeFilter ? <FilterIcon color="primary" sx={{marginRight: 0.5}} /> : undefined}
                    title={typeof title === "string" ? translate(title) : title}
                    className={classes.cardHeader}
                    titleTypographyProps={{className: classes.titleTypography}}
                />
            )}
            <CardContent className={classes.cardContent}>
                {noData ? (
                    <Grid container justifyContent="center" alignContent="center" className={classes.noDataContainer}>
                        <Grid xs={12} style={{textAlign: "center"}}>
                            <WidgetErrorIcon className={classes.noDataIcon}/>
                        </Grid>
                        <Grid xs={12}>
                            <Typography variant="h6" className={classes.noDataMessage}>
                                {translate("stratosReporting.noData.message")}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : children}
            </CardContent>
        </BdsCard>
    );
};